import React from "react";
import Metadata from "../components/metadata/metadata";
import Layout from "../components/layout/layout";
import Prologue from "../components/index/prologue";
import Partners from "../components/index/partners";
import SlideShow from "../components/index/SlideShow";

const Index = () => {
  return (
    <Layout>
      <Metadata
        title="Home"
        description="Hope Labs is a social enterprise helping charities and social enterprises to share their inspiring stories with the world."
      />
      <Prologue />
      <SlideShow />
      <Partners />
    </Layout>
  );
};

export default Index;
