import TalkOneLogo from "../../images/partners/TalkOne-Logo.svg";
import QuasiScience from "../../images/partners/QuasiScience-Logo.svg";

// Add no more than 2/3 external links each
export default {
  description:
    "As a young start-up, Hope Labs has a bright future ahead but also many challenges to overcome. We are grateful to all our partners for their invaluable support and for believing in us.",
  P1: {
    Name: "TalkOne",
    Avatar: TalkOneLogo,
    Role: "Telecoms Partner",
    Short:
      "With TalkOne small businesses have access to reliable cloud-based PBX solutions and telephony services.",
    Long:
      "With TalkOne small businesses have access to reliable cloud-based PBX solutions and telephony services.",
    url: "https://www.talkone.co.uk/",
    linkedin: "https://www.linkedin.com/company/talkone/",
    facebook: "",
    instagram: "",
    youtube: "",
  },
  P2: {
    Name: "QuasiScience",
    Avatar: QuasiScience,
    Role: "Technology Partner",
    Short:
      "QuasiScience is a boutique Engineering Consulting firm specialised in delivering custom software solutions.",
    Long:
      "QuasiScience is a boutique Engineering Consulting firm specialised in delivering custom software solutions.",
    url: "https://quasiscience.com",
    linkedin: "",
    facebook: "",
    instagram: "",
    youtube: "",
  },
  P3: {
    Name: "TBD",
    Avatar: "",
    Role: "TBD",
    Short: "TBD",
    Long: "TBD",
    url: "",
    linkedin: "",
    facebook: "",
    instagram: "",
    youtube: "",
  },
};
