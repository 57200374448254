import React, { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import indexStyle from "./index.module.css";

// const Indicators = (props) => {
// 	const {index, setIndex} = props;
// 	return(
// 		<ol className={indexStyle.carouselIndicators}>
// 			<li></li>
// 			<li></li>
// 			<li></li>
// 		</ol>
// 	)
// }

const SlideShow = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: { type: { eq: "post" }, publish: { eq: "true" } }
          }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          edges {
            node {
              frontmatter {
                title
                date(formatString: "DD MMMM, YYYY")
                featured {
                  childImageSharp {
                    fluid(maxWidth: 1500, maxHeight: 500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                cover {
                  childImageSharp {
                    fluid(maxWidth: 1500, maxHeight: 500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              timeToRead
              excerpt
              id
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <section className={`sectionMain`} role="main">
      <h1 className={"mt-2em"}>Latest Stories</h1>
      <Row>
        <Col lg={10} className={indexStyle.carouselCenter}>
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            keyboard={false}
            interval={6000}
            nextLabel={null}
            prevLabel={null}
            indicators={false}
            fade
          >
            {/* <Indicators /> */}
            {data.allMarkdownRemark.edges.map(edge => {
              let src = edge.node.frontmatter.cover
                ? edge.node.frontmatter.cover.childImageSharp.fluid
                : edge.node.frontmatter.featured.childImageSharp.fluid;
              return (
                <Carousel.Item key={edge.node.frontmatter.title}>
                  <Img
                    fluid={src}
                    alt={edge.node.frontmatter.title}
                    className={indexStyle.carouselImage}
                  />
                  <Carousel.Caption className={indexStyle.carouselCaption}>
                    <Link
                      to={`/stories/${edge.node.fields.slug}/`}
                      className={indexStyle.carouselLink}
                    >
                      <h3 className={indexStyle.carouselTitle}>
                        {edge.node.frontmatter.title}
                      </h3>
                    </Link>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Col>
      </Row>
    </section>
  );
};

export default SlideShow;
