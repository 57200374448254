import React from "react";
import cardStyles from "./card.module.css";
import Rainbow from "../layout/rainbow.js";
import PropTypes from "prop-types";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";
import { IconContext } from "@react-icons/all-files";

const IconStyle = props => {
  return (
    <IconContext.Provider value={{ className: "gray-icons" }}>
      {props.children}
    </IconContext.Provider>
  );
};

const Card = props => {
  const { image, title, subtitle, text, footerText } = props;
  let iconShade = true;
  if (props.iconShade === false) {
    iconShade = false;
  }
  let iconSquare = false;
  if (props.iconSquare === true) {
    iconSquare = true;
  }

  return (
    <div className={`${cardStyles.card}`}>
      <div className={cardStyles.standardFrame}>
        <span className={cardStyles.helper}></span>
        <img
          src={image}
          className={`${cardStyles.cardAvatar} ${
            iconShade === true ? cardStyles.shade : null
          } ${iconSquare === false ? null : cardStyles.rectangular}`}
          alt={`${title} cover`}
        ></img>
      </div>
      <div className={`${cardStyles.cardBody} ${cardStyles.justifyContent}`}>
        <h5 className={cardStyles.cardTitle}>
          {title}
          <br />
          <small className={cardStyles.cardSubtitle}>{subtitle}</small>
        </h5>
        <p className={cardStyles.cardText}>{text}</p>
        {props.rainbowStripe === true ? <Rainbow /> : null}
        <div
          className={`${cardStyles.cardFooter} ${cardStyles.justifyContent}`}
        >
          {footerText !== undefined ? (
            <p className="text-muted">{footerText}</p>
          ) : null}
          {props.url !== undefined && props.url.length > 0 ? (
            <a
              className="btn"
              href={props.url}
              alt={`External Website link for ${title}`}
            >
              <IconStyle>
                <FaGlobe />
              </IconStyle>
            </a>
          ) : null}
          {props.linkedin !== undefined && props.linkedin.length > 0 ? (
            <a
              className="btn"
              href={props.linkedin}
              alt={`Linkedin link for ${title}`}
              role="button"
            >
              <IconStyle>
                <FaLinkedin />
              </IconStyle>
            </a>
          ) : null}
          {props.instagram !== undefined && props.instagram.length > 0 ? (
            <a
              className="btn"
              href={props.instagram}
              alt={`Instagram link for ${title}`}
              role="button"
            >
              <IconStyle>
                <FaInstagram />
              </IconStyle>
            </a>
          ) : null}
          {props.facebook !== undefined && props.facebook.length > 0 ? (
            <a
              className="btn"
              href={props.facebook}
              alt={`Facebook link for ${title}`}
              role="button"
            >
              <IconStyle>
                <FaFacebook />
              </IconStyle>
            </a>
          ) : null}
          {props.youtube !== undefined && props.youtube.length > 0 ? (
            <a
              className="btn"
              href={props.youtube}
              alt={`YouTube link for ${title}`}
              role="button"
            >
              <IconStyle>
                <FaYoutube />
              </IconStyle>
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Card;

Card.defaultProps = {
  cardClass: "card h-100",
  image: "...",
  title: "Empty Title",
  subtitle: "...",
  text: "...",
  website: "",
  youtube: "",
  facebook: "",
  linkedin: "",
  rainbowStripe: false,
  iconShade: true,
  iconSquare: false,
};

Card.propTypes = {
  cardClass: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  website: PropTypes.string,
  youtube: PropTypes.string,
  facebook: PropTypes.string,
  linkedin: PropTypes.string,
  rainbowStripe: PropTypes.bool,
  iconShade: PropTypes.bool,
  iconSquare: PropTypes.bool,
};
