import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Prologue = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(
          relativePath: { eq: "images/covers/Artistic_Mountain_Range.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 450) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <section role="main">
      <Row className={`imageBox no-margin`}>
        <div className={`imageFilter d-none d-sm-block`}></div>
        <Img fluid={data.file.childImageSharp.fluid} alt="cover" />
        <h1 className={`overlayTitle d-none d-sm-block`}>
          {"The New Voice of Change"}
        </h1>
      </Row>
      <Row className={`presentation justify`}>
        <Col xs={12} md={11} lg={11} xl={11}>
          <h1 className={"mt-2em"}>Mission</h1>
          <p>
            Hope Labs aims to support charities and social enterprises by
            sharing their stories to inspire, leave a mark, and change the
            world. We leverage our technology platform to offer tailored PR and
            Marketing services for all social ventures, and our independent
            research to promote efficiency and transparency in the social
            sector.
          </p>
          <h1 className={"mt-2em"}>Discover</h1>
          <p>
            Hope Labs is an independent PR and Marketing company specialised in
            services for social ventures. Hope Labs is also a social enterprise:
            we are committed to making a positive contribution to society by
            publishing our own research and donating to the charities that we
            identified as "outstanding" over the year.
          </p>
          <p>
            You can read more of our{" "}
            <Link to={"/stories"}>stories and articles</Link> on this portal and
            get in touch with us from our{" "}
            <Link to={"/contact"}>contact page</Link> or your favorite Social
            Media.
          </p>
        </Col>
      </Row>
    </section>
  );
};

export default Prologue;
