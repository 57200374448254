import React from "react";
// import { Link } from "gatsby"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Card from "../card/cardFactory";

import partnersDescription from "../../documents/descriptions/partnersDescription";

const Partners = () => {
  return (
    <section className={"sectionMain"} role="main">
      <Row className={`presentation justify`}>
        <Col xs={12} md={11} lg={11} xl={11}>
          <h1 className={"mt-2em"}>Partners</h1>
          <p>{partnersDescription.description}</p>
        </Col>
      </Row>
      <Row className="justify md-2 no-margin">
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <Card
            title={partnersDescription.P1.Name}
            subtitle={partnersDescription.P1.Role}
            image={partnersDescription.P1.Avatar}
            text={partnersDescription.P1.Short}
            url={partnersDescription.P1.url}
            facebook={partnersDescription.P1.facebook}
            youtube={partnersDescription.P1.youtube}
            linkedin={partnersDescription.P1.linkedin}
            iconShade={false}
            iconSquare={true}
          />
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <Card
            title={partnersDescription.P2.Name}
            subtitle={partnersDescription.P2.Role}
            image={partnersDescription.P2.Avatar}
            text={partnersDescription.P2.Short}
            url={partnersDescription.P2.url}
            facebook={partnersDescription.P2.facebook}
            youtube={partnersDescription.P2.youtube}
            linkedin={partnersDescription.P2.linkedin}
            iconShade={false}
            iconSquare={true}
          />
        </Col>
        {/* <div className="col">
          <Card 
            title ="Placeholder Inc." 
            subtitle ="Subtitle" 
            image={testImage} 
            text="This is an example of description"
            url="https://example.com"
          />
        </div> */}
      </Row>
    </section>
  );
};

export default Partners;
